import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Marquee from 'react-fast-marquee'
import { ThemeContext } from '../context/ThemeContext'
import Layout from '../components/Layout'
import { H2, Excerpt } from '../components/Text'
import { Grid, CssGrid, Column } from '../components/Grid'
import Section from '../components/Section'
import StyledLink from '../components/StyledLink'
import SplitText from '../components/SplitText'
import Tile from '../components/Tile'
import Div from '../components/Div'
import { StyledHero } from '../components/Hero'
import FrontH1 from '../components/FrontH1'
import { getActiveLangPath } from '../components/SelectLanguage'
import { breakpoints, vw, fluidRange, breakpointNr } from '../style'
import { routes } from '../routes'
import getMetaFromPost from '../lib/getMetaFromPost'

const CaseGrid = styled(CssGrid)`
  grid-template-columns: 1fr !important;
  > * {
    grid-column: auto;
  }
  @media ${breakpoints.small} {
    grid-auto-flow: row dense;
  }

  @media ${breakpoints.medium} {
    grid-template-columns: 1fr 1fr !important;
  }

  /* > * {
    @media ${breakpoints.small} {
      grid-row: span 2;
      grid-column: grid-start / span 6;
    }
  } */

  /* > *:nth-child(1),
  > *:nth-child(4),
  > *:nth-child(5) {
    @media ${breakpoints.small} {
      grid-row: span 5;
      min-height: ${vw(752)};
    }
  } */

  /* > *:nth-child(2),
  > *:nth-child(4) {
    @media ${breakpoints.small} {
      grid-column-start: col-end 6;
    }
  } */

  /* > *:nth-child(5) {
    grid-column: grid;
  } */
`

const FrontH2 = styled(FrontH1)`
  font-size: 3em;

  @media ${breakpoints.large} {
    font-weight: 400;
    font-size: ${fluidRange({
      min: 100,
      max: 150,
      viewportMin: breakpointNr.large,
      viewportMax: breakpointNr.xlarge,
    })};
  }
`

const ServicesExceprt = styled(FrontH1)`
  font-size: ${fluidRange({
    min: 40,
    max: 100,
    viewportMin: breakpointNr.large,
    viewportMax: breakpointNr.xlarge,
  })} !important;
`

export default function Index({ data, location }) {
  const theme = React.useContext(ThemeContext)

  const {
    page,
    subtitle,
    marqueeText,
    featuredCases,
    casesLinkText,
  } = data.contentfulPage

  const marqueArray = marqueeText
    ?.split('—')
    .map(item => `${item.trim()} —`)
    .slice(0, -1)

  return (
    <Layout meta={getMetaFromPost(data.contentfulPage)} location={location}>
      <StyledHero pb={[3, 7]}>
        <Grid gutter="none" mobileGutter="none">
          <Column sm={12}>
            <FrontH2 heroTitle={page.title} />
            {subtitle && <ServicesExceprt heroTitle={subtitle} />}
            {/* <Excerpt textColor={theme.color}>
              {isDefaultLanguage()
                ? 'We craft exceptional e-commerce experiences and build digital flagship stores for brands worldwide.'
                : 'fokuserat på branding, design system och utveckling.'}
            </Excerpt> */}
          </Column>
        </Grid>
      </StyledHero>
      <Grid gutter="none" mobileGutter="none">
        {marqueeText && (
          <Marquee autoFill className="overflow-y-hidden" pauseOnHover>
            {marqueArray.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Column md={12} pt={10} key={index}>
                <H2 as="h2" mb="0" textColor={theme.color}>
                  {item}
                </H2>
              </Column>
            ))}
          </Marquee>
        )}
      </Grid>
      <Section
        id="case-section"
        pt={[3, 6]}
        pb={[8, 16]}
        pr={[0.5, 0]}
        pl={[0.5, 0]}
      >
        <CaseGrid>
          {featuredCases.map(node => (
            <Tile
              key={node.id}
              url={`${getActiveLangPath()}/work/${node.slug}`}
              title={node.client.name}
              image={node.featuredImage}
              video={node?.featuredVideo}
              tags={node.tags}
              bg={node.color}
              mb="0"
              // awards={node.awards}
            />
          ))}
        </CaseGrid>

        <Grid gutter="none" mobileGutter="none" textAlign="center">
          <Column>
            <Div mt={[3, 6]}>
              <StyledLink
                to={`${getActiveLangPath()}/${routes.work.link}`}
                variant="large"
                textColor={theme.color}
                styleVariant={theme.theme}
              >
                <SplitText copy={casesLinkText} role="heading" type="chars" />
                <SplitText copy={casesLinkText} role="heading" type="chars" />
              </StyledLink>
            </Div>
          </Column>

          <Column
            paddingRight={0}
            paddingLeft={0}
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            width="100%"
            flex
            pt={10}
          >
            {page?.excerpt && (
              <Excerpt textAlign="center" textColor={theme.color}>
                {page.excerpt.excerpt}
              </Excerpt>
            )}
          </Column>
        </Grid>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $locale: String!) {
    contentfulPage: contentfulStartPage(
      page: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      subtitle
      marqueeText
      casesLinkText
      newsLinkText
      newsHeader
      featuredCases {
        id
        title
        slug
        featuredVideo {
          file {
            contentType
            url
          }
        }
        featuredImage {
          fluid(quality: 80, maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        tags {
          ... on ContentfulTags {
            id
            name
          }
        }
        client {
          name
        }
      }
      page {
        title
        slug
        excerpt {
          excerpt
        }
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          og: resize(width: 1200, height: 630, quality: 80) {
            src
          }
        }
      }
    }
  }
`
